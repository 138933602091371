import DataTable from "../../components/DataTable";
import useSWR from 'swr'
import { DELETE, GET, POST, PUT } from "../../utils/requestActionConstants";
import { API_ROUTE } from "../../utils/commonConstants";
import { fetcher, processReq } from "../../helpers/processRequest";
import { Button, Form, Input, Modal, message, notification } from "antd";
import { useState } from "react";
import TextArea from "antd/es/input/TextArea";
import ImageGallery from "../../components/ImageGallery";

const Blog = () => {
    const postsData = useSWR({method: GET, url: API_ROUTE + '/posts'} , fetcher);
    const imagesData = useSWR({method: GET, url: API_ROUTE + '/auth/upload/'} , fetcher);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [selectedPost, setSelectedPost] = useState({});
    const [form] = Form.useForm();

    const addRecord = () => {
        setSelectedPost({});
        form.resetFields();
        setIsModalOpen(true);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const onFinish = async (data) => {
        if (selectedPost.id) {
            createPost({...data, id: selectedPost.id}, PUT);
        } else {
            createPost(data, POST);
        }
    }

    const createPost = async (data, method) => {
        const createdPost = await processReq(method, API_ROUTE + '/auth/posts', data);

        setIsModalOpen(false);
        api['success']({ message: 'Post Updated!', description: createdPost.title});
        
        postsData.mutate();
    }

    const deletePost = async (record) => {
        const response = await processReq(DELETE, API_ROUTE + '/auth/posts/dest/' + record.id, record);
        message.success('Post has been deleted');
        postsData.mutate();
    }

    const editPost = (record) => {
        setSelectedPost(record);
        form.setFieldsValue(record);
        setIsModalOpen(true);
    }
    return (
        <div className="pop-left fade md:p-10 shadow-lg rounded-xl bg-white/60">
            {contextHolder}
            <div className="text-3xl">Posts</div>
            <div className="text-3xl pb-5 text-right"><Button onClick={addRecord}>Add Post</Button></div>
            <div>
                <DataTable 
                    columns={[
                    "id",
                    "title",
                    "body",
                    ]}
                    data={postsData.data}
                    isLoading={postsData.isLoading}
                    deleteAction={deletePost}
                    editAction={editPost}
                />
            </div>

            <Modal maskClosable={false} title="Add New Post" open={isModalOpen} onCancel={handleCancel} okButtonProps={{hidden: true}} cancelButtonProps={{hidden: true}}>
                <Form form={form} className="pt-5" onFinish={onFinish} layout="vertical">
                    <Form.Item label='Post Title' name={'title'} rules={[{required: true, message: 'Please Enter Title!'}]}>
                        <Input placeholder="Enter Title Here..." />
                    </Form.Item>
                    <Form.Item label='Post Title' name={'body'} rules={[{required: true, message: 'Please Enter Body!'}]}>
                        <TextArea placeholder="Enter Body Here..."/>
                    </Form.Item>
                    <Form.Item label='Baner Image URL' name={'mainImgUrl'}>
                        <Input placeholder="Enter Main Image URL..."/>
                    </Form.Item>

                    <Form.List name="images">
                        {(fields, { add, remove }) => (
                            <>
                            {fields.map(({ key, name, ...restField }) => (
                                <div className="flex" key={key}>
                                    <div className="grow">
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'imageUrl']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Url cannot be empty',
                                                }
                                            ]}
                                            style={{width: '100%'}}
                                        >
                                            <Input placeholder="Image Url" style={{width: '100%'}} />
                                        </Form.Item>
                                    </div>
                                    <div className="cursor-pointer pl-5" onClick={() => remove(name)}>Delete</div>
                                </div>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} style={{width: '100%'}}>
                                    Add Image URL
                                </Button>
                            </Form.Item>
                            </>
                        )}
                        </Form.List>
                        
                    <Form.Item className="text-center">
                        <Button htmlType="submit">{selectedPost.id ? "Save Changes" : "Create New Post"}</Button>
                    </Form.Item>
                </Form>
                <div className="text-xl">Click on an image to copy url and update to post</div>
                <ImageGallery imagesData={imagesData}/>
            </Modal>
        </div>
    )
}

export default Blog;