// This is for development environment
// export const SERVER_ROUTE = "http://localhost:3000/"
// export const API_ROUTE = "http://localhost:3000/api"
// export const CLIENT_ROUTE = "http://localhost:3001"

// // This is for production environment
export const SERVER_ROUTE = "https://server.fireprotectionspecialists.com.au/"
export const API_ROUTE = "https://server.fireprotectionspecialists.com.au/api";
export const CLIENT_ROUTE = "https://admin.fireprotectionspecialists.com.au";

// This is for QA environment
// export const SERVER_ROUTE = "https://ceylon-ladies-campus.uc.r.appspot.com/"
// export const API_ROUTE = "https://ceylon-ladies-campus.uc.r.appspot.com/api/v2";
// export const CLIENT_ROUTE = "https://qa.clcsl.edu.lk";

