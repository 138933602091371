import React, {useContext, useEffect, useState} from 'react'
import {Outlet} from "react-router-dom";
import Sidebar from '../components/SideBar';
import {Button, Switch} from 'antd';
import Login from '../components/Login';
import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, PoweroffOutlined, UserOutlined } from '@ant-design/icons';
import { AuthContext } from '../context/AuthContextProvider';

function Home() {
    const {logOut, isLoggedIn, user} = useContext(AuthContext);
    const [hidden, setHidden] = useState(false);
    const [sideBarStyles, setSideBarStyles] = useState({maxWidth: 200});

    useEffect(() => {
        let styles = {transition: '0.4s', overflow: 'hidden'};

        if (hidden) {
            styles.maxWidth = 10;
            styles.minWidth = 10;
        } else {
            styles.maxWidth = 200;
            styles.minWidth = 200;
        }

        setSideBarStyles(styles);
    }, [hidden]);

    if (isLoggedIn) {
        return (
            <div className={''}>
                <div className='flex gradient-bg-2'>
                    <div className='gradient-bg-2 h-screen absolute z-40 md:relative pt-10 md:pt-0'
                         style={sideBarStyles} onMouseEnter={() => setHidden(false)}><Sidebar hidden={hidden} setHidden={setHidden}/>
                    </div>
                    <div className='grow overflow-y-auto h-screen'>
                        <div className='sticky top-0 gradient-bg-1 pr-5 py-2 z-40 flex'>
                            <div><Button type='link' onClick={() => setHidden((old) => !old)}>{hidden ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}</Button></div>

                            <div className='grow text-right px-2 text-sm'><UserOutlined className='pr-2' />{user.firstName} {user.lastName}<Button type='link' onClick={logOut}><PoweroffOutlined /></Button></div>
                        </div>
                        <div className='p-10'><Outlet/></div>
                    </div>
                </div>
            </div>
        )
    } else {
        return <Login/>
    }


}

export default Home