import UploadFile from "../../components/UploadFile";
import useSWR from 'swr'
import { DELETE, GET } from "../../utils/requestActionConstants";
import { API_ROUTE } from "../../utils/commonConstants";
import { fetcher, processReq } from "../../helpers/processRequest";
import ImageGallery from "../../components/ImageGallery";

const FileManager = () => {
    const imagesData = useSWR({method: GET, url: API_ROUTE + '/auth/upload/'} , fetcher);

    const onReload = () => {
        imagesData.mutate();
    }

    const deleteImage = async (file) => {
        if (file.response) {
            let response = await processReq(DELETE, API_ROUTE + "/auth/upload/" + file.response.id);

            onReload();
        } else {
            alert('cannot be deleted');
        }
    }

    return (
        <div className="pop-left fade md:p-10 shadow-lg rounded-xl bg-white/60">
            <div className="text-2xl">File Manager</div>
            <div className="text-xm">Upload and manage your files here!</div>
            <div className="pt-10">
                <UploadFile onReload={onReload} deleteImage={deleteImage}/>
            </div>
            <div>
                <ImageGallery deleteImage={deleteImage} imagesData={imagesData}/>
            </div>
        </div>
    );
}

export default FileManager;