import DataTable from "../../components/DataTable";
import useSWR from 'swr'
import { DELETE, GET, POST, PUT } from "../../utils/requestActionConstants";
import { API_ROUTE } from "../../utils/commonConstants";
import { fetcher, processReq } from "../../helpers/processRequest";

const Blog = () => {
    const enqData = useSWR({method: GET, url: API_ROUTE + '/enquiry'} , fetcher);

    return (
        <div className="pop-left fade md:p-10 shadow-lg rounded-xl bg-white/60">
            <div className="text-3xl">Enquiry Management</div>
            <div className="text-3xl pb-5 text-right"></div>
            <div>
                <DataTable 
                    columns={[
                    "id",
                    "surName",
                    "email",
                    "phone",
                    "message",
                    "createdAt"
                    ]}
                    data={enqData.data}
                    isLoading={enqData.isLoading}
                />
            </div>
        </div>
    )
}

export default Blog;