import Button from "../../components/Button";
import { BarChart } from "../../components/chart/BarChart";
import { LineChart } from "../../components/chart/LineChart";
import { PieChart } from "../../components/chart/PieChart";

const Dashboard = () => {
    return (
        <div className="pop-left fade px-5 md:p-10 shadow-lg rounded-xl bg-white/60 hero-bg">
            <div className=""></div>
        </div>
    );
}

export default Dashboard;