import React, { useContext, useState } from 'react';
import { Alert, Button, Form, Input, message } from 'antd';
import axios from 'axios';
import { processReq } from '../helpers/processRequest';
import { GET } from '../utils/requestActionConstants';
import { API_ROUTE } from '../utils/commonConstants';
import { AuthContext } from '../context/AuthContextProvider';

const Login = () => { 
    const {login, isLoggedIn} = useContext(AuthContext);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const onFinish = async ({email, password}) => {
        setIsLoading(true);
        let result = await login(email, password);

        if (result) {
            setIsError(true);
            setErrorMessage(result);
            message.error(result);
        }
        setIsLoading(false);
    };

    const onFinishFailed = (errorInfo) => {
        console.log(errorInfo)
        setIsLoading(false);
    };

    const onClose = (e) => {
        setIsError(false);
        setIsLoading(false);
    };

    // const sendGetRequest = async () => {
    //     try {
    //         let server = "https://ceylon-ladies-campus.uc.r.appspot.com";
    //         // server = "http://localhost:3000";
    
    //         const result = await axios.get(server + '/test-gcloud');
    
    //         console.log(result.data);
    //     } catch (error) {
    //         console.log(error)
    //     }
        
    // }

    // const sendPostRequest = async () => {
    //     try {
    //         let server = "https://ceylon-ladies-campus.uc.r.appspot.com";
    //         // server = "http://localhost:3000";
    
    //         const result = await axios.post(server + '/test-gcloud');
    
    //         console.log(result.data);
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // const getPosts = async () => {
    //     const result = await processReq(GET, API_ROUTE + '/posts');

    //     console.log(result)
    // }

    return (
    <div>
        <div className='flex h-screen justify-center items-center' style={{
            backgroundImage: 'url(https://t3.ftcdn.net/jpg/03/55/60/70/360_F_355607062_zYMS8jaz4SfoykpWz5oViRVKL32IabTP.jpg)', 
            backgroundImage: 'url(https://colibriwp.com/blog/wp-content/uploads/2019/06/pawel-czerwinski-vI5XwPbGvmY-unsplash.jpg)', 
            backgroundSize: "cover"
        }}>
            <div className='shadow-xl rounded-xl w-1/3 m-auto p-5 bg-white/60'>
                <div className='text-3xl text-center py-10'>Sign In</div>
                <Form
                    name="basic"
                    labelCol={{
                    span: 6,
                    }}
                    wrapperCol={{
                    span: 16,
                    }}
                    // style={{
                    // maxWidth: 600,
                    // }}
                    initialValues={{
                    remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className='m-auto'
                >
                    <Form.Item
                        label="User Email"
                        name="email"
                        initialValue={''}
                        rules={[
                            {
                            required: true,
                            message: 'Please input your email!',
                            },
                        ]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        initialValue={''}
                        rules={[
                            {
                            required: true,
                            message: 'Please input your password!',
                            },
                        ]}
                    >
                    <Input.Password />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 6,
                            span: 16,
                        }}
                    >
                    <Button htmlType="submit" loading={isLoading}>Sign In</Button>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 4,
                            span: 16,
                        }}
                    >
                        {isError ? 
                            <Alert
                                message={errorMessage}
                                type="warning"
                                closable
                                afterClose={onClose}
                            />
                        : '' }
                    </Form.Item>
                </Form>  
            </div> 
        </div>
        
        {/* <Button onClick={sendPostRequest}>Test Post Request</Button>
        <Button onClick={sendGetRequest}>Test Get Request</Button>
        <Button onClick={getPosts}>Get Posts</Button> */}
    </div>
  );
}

export default Login;