import DataTable from "../../components/DataTable";
import useSWR from 'swr'
import { DELETE, GET, POST, PUT } from "../../utils/requestActionConstants";
import { API_ROUTE } from "../../utils/commonConstants";
import { fetcher, processReq } from "../../helpers/processRequest";
import { Button, Form, Input, Modal, Switch, message, notification } from "antd";
import { useRef, useState } from "react";
import ImageGallery from "../../components/ImageGallery";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import FileManager from "../filemanager/FileManager";
// import { ContentState, Editor, EditorState, convertToRaw, convertFromRaw } from "react-draft-wysiwyg";
// import {  convertFromRaw, convertToRaw } from "draft-js";

const ProductMain = () => {
    const productsData = useSWR({ method: GET, url: API_ROUTE + '/products' }, fetcher);
    const imagesData = useSWR({ method: GET, url: API_ROUTE + '/auth/upload/' }, fetcher);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [selectedRecord, setSelectedRecord] = useState({});
    const [form] = Form.useForm();
    const [description, setDescription] = useState('');
    const editorRef = useRef(null);

    const addRecord = () => {
        setSelectedRecord({});
        form.resetFields();
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setDescription('')
    };

    const onFinish = async (data) => {
        data.productDesc = description;
        if (selectedRecord.id) {
            create({ ...data, id: selectedRecord.id }, PUT);
        } else {
            create(data, POST);
        }
    }

    const create = async (data, method) => {
        const created = await processReq(method, API_ROUTE + '/auth/products', data);

        setIsModalOpen(false);
        setDescription('');
        api['success']({ message: 'Data Updated!', description: created.productName});

        productsData.mutate();
    }

    const deleteRecord = async (record) => {
        const response = await processReq(DELETE, API_ROUTE + '/auth/products/dest/' + record.id, record);
        message.success('Record has been deleted');
        productsData.mutate();
    }

    const editRecord = (record) => {
        setSelectedRecord(record);
        setDescription(record.productDesc)
        form.setFieldsValue(record);
        setIsModalOpen(true);
    }

    const reloadDescription = () => {
        setDescription(selectedRecord.productDesc)
    };

    return (
        <div className="pop-left fade md:p-10 shadow-lg rounded-xl bg-white/60">
            {contextHolder}
            <div className="text-3xl">Products</div>
            <div className="text-3xl pb-5 text-right"><Button onClick={addRecord}>Add Product</Button></div>
            <div>
                <DataTable
                    columns={[
                        "id",
                        "productName",
                        "price",
                        "availableQty",
                    ]}
                    data={productsData.data}
                    isLoading={productsData.isLoading}
                    deleteAction={deleteRecord}
                    editAction={editRecord}
                />
            </div>

            <Modal maskClosable={false} width={'100%'} title="Add New Post" open={isModalOpen} onCancel={handleCancel} okButtonProps={{ hidden: true }} cancelButtonProps={{ hidden: true }}>
                <Form form={form} className="pt-5" onFinish={onFinish} layout="vertical">
                    <Form.Item label='Product Name' name={'productName'} rules={[{ required: true, message: 'Please Enter Product Name!' }]}>
                        <Input placeholder="Enter Product Name Here..." />
                    </Form.Item>
                    <Form.Item label='Price' name={'price'} rules={[{ required: true, message: 'Please Enter Price!' }]}>
                        <Input type="number" placeholder="Enter Price Here..." />
                    </Form.Item>
                    <Form.Item label='Available Quantity' name={'availableQty'} rules={[{ required: true, message: 'Please Quantity Available!' }]}>
                        <Input type="number" placeholder="Enter Qty Here..." />
                    </Form.Item>
                    <Form.Item label='Product Image' name={'productImage'}>
                        <Input placeholder="Enter Main Image URL..." />
                    </Form.Item>
                    <Form.Item label="Tax Applicable" valuePropName="checked" name={'taxApplicable'}>
                        <Switch />
                    </Form.Item>

                    <Button className="bg-blue-300" onClick={reloadDescription}>Reset Editor</Button>
                    <ReactQuill ref={editorRef} theme="snow" value={description} onChange={setDescription} modules={{
                        toolbar: [
                            [/*{ 'header': [1, 2, false] }*/],
                            ['bold', 'italic', 'underline', 'strike', /*'blockquote'*/],
                            [/*{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }*/],
                            ['link', /*'image'*/],
                            ['clean']
                        ],
                    }} />
                    {/* <div dangerouslySetInnerHTML={{ __html: description }}></div> */}
                    <Form.Item className="pt-5 text-right">
                        <Button htmlType="submit">{selectedRecord.id ? "Save Changes" : "Create New Post"}</Button>
                    </Form.Item>
                </Form>
                <div className="text-xl">Click on an image to copy url and update to product</div>
                <FileManager />
                {/* <ImageGallery imagesData={imagesData} /> */}
            </Modal>
        </div>
    )
}

export default ProductMain;