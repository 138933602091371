import './App.css';
import Root from "./pages/Root";
import Dashboard from './pages/dashboard/Dashboard';
import Blog from './pages/blog/Blog';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.scss';
import { Provider } from 'react-redux';
import store from './store';
import FileManager from './pages/filemanager/FileManager';
import NoticeBoard from './pages/noticeBoard/NoticeBoard';
import Inquiry from './pages/inquiries/Inquiries';
import { createContext, useEffect } from 'react';
// import { io } from 'socket.io-client';
import AuthContextProvider from './context/AuthContextProvider';
import { App as AntAppContext } from "antd";
import ProductMain from './pages/products/ProductsMain';

export const SocketContext = createContext(null);

// const socket = io(SERVER_ROUTE);
const socket = undefined;
const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <div>error 404</div>,
        children: [
            {
                path:"/",
                element: <Dashboard />
            },
            {
                path:"/blog",
                element: <Blog />
            },
            {
                path:"/filemanager",
                element: <FileManager />
            },
            // {
            //   path:"/sms",
            //   element: <SMS />
            // },
            // {
            //   path:"/dormitory",
            //   element: <Dormitory />
            // },
            // {
            //   path:"/noticeboard",
            //   element: <NoticeBoard />
            // },
            {
              path:"/products",
              element: <ProductMain />
            },
            // {
            //   path:"/subjects",
            //   element: <Subject />
            // },
            // {
            //   path:"/lecturers",
            //   element: <Lecturer />
            // },
            // {
            //   path:"/students",
            //   element: <Student />
            // },
            // {
            //   path:"/courses",
            //   element: <Course />
            // },
            {
              path:"/inquiries",
              element: <Inquiry />
            },
            // {
            //   path:"/timetable",
            //   element: <TimeTable />
            // }
        ]
    }
]);

function App() {
  useEffect(() => {
    // socket.on("connect", () => {
    //     console.info('socket instance initiated', socket.id);
    //     sessionStorage.setItem('socketId', socket.id)
    // });

    // socket.on("disconnect", () => {
    // });
  }, [])
  
  return (
    <Provider store={store}>
      <AuthContextProvider>
        <SocketContext.Provider value={socket}>
          <AntAppContext>
            <RouterProvider router={router} />
          </AntAppContext>
        </SocketContext.Provider>
      </AuthContextProvider>
    </Provider>
  );
}

export default App;
