import { Link, useNavigate } from "react-router-dom";
import logo from '../assets/img/logo.png'
import { useEffect, useState } from "react";

function Sidebar(props) {
    const [activeRoute, setActiveRoute] = useState('');
    const navigate = useNavigate();
    const navs = [
        {
            id: 1,
            label: "Dashboard",
            route: "/",
            icon: "icon-home pr-2 pt-1",
            isActive: false,
        },
        {
            id: 2,
            label: "Manage Blog",
            route: "/blog",
            icon: "icon-blog pr-2 pt-1",
            isActive: false,
        },
        {
            id: 3,
            label: "File Manager",
            route: "/filemanager",
            icon: "icon-tasks pr-2 pt-1",
            isActive: false,
        },
        // {
        //     id: 4,
        //     label: "SMS",
        //     route: "/sms",
        //     icon: "icon-email-envelope pr-2 pt-1",
        //     isActive: false,
        // },
        // {
        //     id: 5,
        //     label: "Dormitory",
        //     route: "/dormitory",
        //     icon: "icon-building-o pr-2 pt-1",
        //     isActive: false,
        // },
        // {
        //     id: 6,
        //     label: "Notice Board",
        //     route: "/noticeboard",
        //     icon: "icon-speakerphone pr-2 pt-1",
        //     isActive: false,
        // },
        {
            id: 6,
            label: "Products",
            route: "/products",
            icon: "icon-cart pr-2 pt-1",
            isActive: false,
        },
        // {
        //     id: 7,
        //     label: "Students",
        //     route: "/students",
        //     icon: "icon-graduation-cap pr-2 pt-1",
        //     isActive: false,
        // },
        // {
        //     id: 8,
        //     label: "Subjects",
        //     route: "/subjects",
        //     icon: "icon-graduation-cap-1 pr-2 pt-1",
        //     isActive: false,
        // },
        // {
        //     id: 9,
        //     label: "Lecturers",
        //     route: "/lecturers",
        //     icon: "icon-person pr-2 pt-1",
        //     isActive: false,
        // },
        // {
        //     id: 10,
        //     label: "Courses",
        //     route: "/courses",
        //     icon: "icon-book pr-2 pt-1",
        //     isActive: false,
        // },
        {
            id: 11,
            label: "Enquiries",
            route: "/inquiries",
            icon: "icon-help pr-2 pt-1",
            isActive: false,
        },
        // {
        //     id: 12,
        //     label: "Time Table",
        //     route: "/timetable",
        //     icon: "icon-calendar pr-2 pt-1",
        //     isActive: false,
        // }
    ];

    const switchRoute = (route) => {
        navigate(route);
        setActiveTab();
    }

    const setActiveTab = () => {
        setActiveRoute(window.location.pathname.split('/')[1]);
    }

    useEffect(() => {
        setActiveTab();
    }, []);

    return (
        <div className="h-full px-4 gradient-bg-5 dropshadow rounded-l">
            <div className="p-5 cursor-pointer"><img src={logo} width={100} alt={'CLC'} onClick={() => switchRoute(navs[0].route)}/></div>
            <ul>{navs.map(nav => (<li key={nav.id} className={`cursor-pointer hover:bg-slate-300 ease-in duration-300 rounded-md px-4 py-2 m-2 flex justify-left items-center text-sm ${('/' + activeRoute) === nav.route ? "gradient-bg-1" : "bg-slate-100"}`} onClick={() => switchRoute(nav.route)}><div className={nav.icon}></div><div>{nav.label}</div></li>))}</ul>
            {!props.hidden ? <div className="md:hidden" style={{height: '100vh', width: '100vw', position: 'fixed', top: 0, zIndex: -3, backdropFilter: 'blur(5px)'}} onMouseEnter={() => props.setHidden(true)}></div> : ''}
        </div>

    );
}

export default Sidebar;